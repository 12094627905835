<template>
  <v-container fill-height fluid text-xs-center class="pa-0">
    <v-row>
      <v-col>
        <v-card class="fill-height container--fluid" width="100vw" flat tile>
          <v-row justify="start">
            <v-col cols="12">
              <v-card
                class="d-flex fill-height d-flex fluid pa-0 ma-0"
                flat
                tile
                width="100vw"
              >
                <v-scroll-x-transition appear>
                  <v-img
                    src="@/assets/images/bg-breadcrumbs.jpg"
                    transition="slide-x-transition"
                    position="center"
                    width="100%"
                    height="200"
                    max-height="200"
                  >
                    <v-container fluid class="fill-height align-end">
                      <v-row align="end" justify="start">
                        <v-col cols="12">
                          <div class="white--text">
                            <span
                              class="white--text pl-5 text-h4 text-md-h3 font-weight-black"
                            >
                              Contacts...
                            </span>
                          </div>
                        </v-col>
                        <v-col cols="12">
                          <v-breadcrumbs dark :items="items">
                            <template v-slot:divider>
                              <v-icon>mdi-arrow-right</v-icon>
                            </template>
                            <template v-slot:item="{ item }">
                              <v-breadcrumbs-item
                                :href="item.href"
                                :disabled="item.disabled"
                              >
                                <span class="white--text">
                                  {{ item.text }}
                                </span>
                              </v-breadcrumbs-item>
                            </template>
                          </v-breadcrumbs>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-img>
                </v-scroll-x-transition>
              </v-card>
            </v-col>
            <v-col cols="12" class="align-center justify-left">
              <v-card class="ma-0 ma-md-5" flat tile>
                <v-container fluid class="mx-0 mt-1">
                  <v-row>
                    <v-col cols="12" md="7">
                      <v-container fluid class="fill-height">
                        <v-row justify="start">
                          <v-col cols="12">
                            <span
                              class="text-h4 font-weight-bold headline text-uppercase"
                            >
                              Contact Us
                            </span>
                            <div class="boxdivider"></div>
                          </v-col>
                          <v-col cols="12">
                            <v-responsive
                              :max-width="
                                $vuetify.breakpoint.xsOnly ? 400 : '100%'
                              "
                            >
                              <span
                                class="text-body-1 font-weight-regular grey--text text--darken-2"
                              >
                                <p>
                                  If you are a patient and require care one of
                                  our specialist doctors, or affiliated
                                  companies, fill out the following form and we
                                  will contact you shortly.
                                </p>
                              </span>
                            </v-responsive>
                          </v-col>
                          <v-col cols="12">
                            <v-card class="ma-0" elevation="0" rounded>
                              <v-form
                                ref="form"
                                v-model="valid"
                                lazy-validation
                                action="/admin/wireframe"
                              >
                                <v-container class="mx-0 mt-0">
                                  <v-row dense>
                                    <v-col cols="12">
                                      <v-text-field
                                        dense
                                        v-model="contactItem.name"
                                        label="Name and last name"
                                        rounded
                                        filled
                                        :rules="textRules"
                                        required
                                      >
                                      </v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                      <v-text-field-simplemask
                                        v-model="contactItem.phone"
                                        label="Phone"
                                        v-bind:properties="{
                                          dense: true,
                                          prefix: '',
                                          suffix: '',
                                          readonly: false,
                                          disabled: false,
                                          outlined: false,
                                          rounded: true,
                                          filled: true,
                                          placeholder: '',
                                          required: true,
                                          rules: textRules,
                                        }"
                                        v-bind:options="{
                                          inputMask: '(###) ###-####',
                                          outputMask: '##########',
                                          empty: null,
                                          applyAfter: false,
                                          alphanumeric: false,
                                          lowerCase: false,
                                        }"
                                        v-bind:focus="focus"
                                        v-on:focus="focus = false"
                                      />
                                    </v-col>
                                    <v-col cols="12">
                                      <v-text-field
                                        dense
                                        v-model="contactItem.email"
                                        :rules="emailRules"
                                        label="Email"
                                        rounded
                                        filled
                                        required
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12">
                                      <v-text-field
                                        dense
                                        v-model="contactItem.service"
                                        label="Solicited service"
                                        :rules="textRules"
                                        rounded
                                        filled
                                        required
                                      ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" v-show="false">
                                      <v-textarea
                                        dense
                                        v-model="contactItem.comment"
                                        label="Comment"
                                        no-resize
                                        rounded
                                        filled
                                        rows="6"
                                        counter
                                        required
                                      >
                                      </v-textarea>
                                    </v-col>
                                  </v-row>
                                </v-container>
                                <v-card-actions class="mt-0 pb-0 pl-5">
                                  <v-btn
                                    :disabled="!valid"
                                    color="primary"
                                    rounded
                                    large
                                    @click="saveContact"
                                  >
                                    Send
                                  </v-btn>
                                </v-card-actions>
                              </v-form>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>
                    <v-col cols="12" md="5" class="pa-5">
                      <v-card elevation="0">
                        <v-container fluid class="fill-height">
                          <v-row align="start" dense>
                            <v-col cols="12">
                              <v-card-title
                                class="text-h5 pl-0 ml-0 blue--text"
                              >
                                <span class="headline"
                                  >Contact information</span
                                >
                              </v-card-title>
                            </v-col>
                            <v-col cols="12">
                              <span class="text-button font-weight-thin"
                                >Address:</span
                              >
                              <v-divider></v-divider>
                            </v-col>
                            <v-col cols="12">
                              <v-container>
                                <v-row align-content="strech" justify="start">
                                  <v-col cols="1">
                                    <v-icon color="primary"
                                      >mdi-map-marker</v-icon
                                    >
                                  </v-col>
                                  <v-col>
                                    <span
                                      class="text-body-2 font-weight-ligh grey--text text--darken-1"
                                    >
                                      {{ company.address }}
                                    </span>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-col>
                            <v-col cols="12">
                              <span class="text-button font-weight-thin"
                                >Phones:</span
                              >
                              <v-divider></v-divider>
                            </v-col>
                            <v-col cols="12">
                              <v-container>
                                <v-row align-content="strech" justify="start">
                                  <v-col cols="1">
                                    <v-icon color="primary">mdi-phone</v-icon>
                                  </v-col>
                                  <v-col>
                                    <span
                                      class="text-body-2 font-weight-ligh grey--text text--darken-1"
                                    >
                                      <!--MEX +52 {{ company.phone1 | formatPhone }}, USA +1 {{ company.phone2 | formatPhone }}-->
                                      {{ company.phone1 | formatPhone("MX") }},
                                      {{ company.phone2 | formatPhone("US") }}
                                    </span>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-col>
                            <v-col cols="12">
                              <span class="text-button font-weight-thin">
                                E-mail:
                              </span>
                              <v-divider></v-divider>
                            </v-col>
                            <v-col cols="12">
                              <v-container>
                                <v-row align-content="strech" justify="start">
                                  <v-col cols="1">
                                    <v-icon color="primary">mdi-email</v-icon>
                                  </v-col>
                                  <v-col>
                                    <span
                                      class="text-body-2 font-weight-ligh grey--text text--darken-1"
                                    >
                                      {{ company.email }}
                                    </span>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackBar" :timeout="2000">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" icon @click="snackBar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import contactsService from "@/providers/ContactsService";
import companyService from "@/providers/CompanyService";

export default {
  name: "ContactsComponent",
  data: () => ({
    snackBar: false,
    snackText: "",
    focus: false,
    valid: true,
    contactItem: {
      name: "",
      phone: "",
      email: "",
      service: "",
      comment: "",
    },
    defaultContactItem: {
      name: "",
      phone: "",
      email: "",
      service: "",
      comment: "",
    },
    company: {
      id: 0,
      name: "",
      address: "",
      email: "",
      web_page: "",
      phone1: "",
      phone2: "",
      facebook_profile: "",
      instagram_profile: "",
      youtube_video: "",
      logo_url: "",
    },
    textRules: [(v) => !!v || "Los datos de este campo son obligatorio!"],
    emailRules: [
      (v) => !!v || "El Correo Electrónico es obligatorio",
      (v) => /.+@.+\..+/.test(v) || "El Correo Electrónico debe ser válido",
    ],
    items: [
      {
        text: "Home",
        disabled: false,
        href: "/homepage",
      },
      {
        text: "Contacts",
        disabled: true,
        href: "/contact",
      },
    ],
  }),
  filters: {
    formatPhone(phn, country) {
      // console.log('phn:', phn);
      if (phn == "undefined" || phn == null || phn === "") {
        return country;
      }
      switch (country) {
        case "US":
          return (
            "USA +1 (" +
            phn.substring(0, 3) +
            ") " +
            phn.substring(3, 6) +
            "-" +
            phn.substring(6)
          );
        case "MX":
          return (
            "MEX +52 (" +
            phn.substring(0, 3) +
            ") " +
            phn.substring(3, 6) +
            "-" +
            phn.substring(6)
          );
        default:
          return country;
      }
    },
    formatPhoneNumber(value) {
      if (value === null) {
        return "";
      }
      return (
        "(" +
        value.substring(0, 3) +
        ") " +
        value.substring(3, 6) +
        "-" +
        value.substring(6)
      );
    },
  },
  methods: {
    getCompany() {
      companyService.getViewData().then((record) => {
        // console.log("Record for Company: ", record.value);
        if (record.value !== null) {
          this.company = record.value;
          // console.log("company: ", this.company);
        }
      });
    },
    saveContact() {
      this.$refs.form.validate();
      if (this.$refs.form.validate(true)) {
        // Agrega el registro por el metodo POST
        contactsService.addRecord(this.contactItem).then((result) => {
          if (result.success) {
            this.contactItem = Object.assign({}, this.defaultContactItem);
            this.snackText = "Datos de contacto registrado con éxito...";
          } else {
            this.snackText =
              "Un error impidió guardar el Dato de Contacto requerido!";
          }
          this.snackBar = true;
          this.$refs.form.reset();
        });
      }
    },
  },
  mounted() {
    this.contactItem = Object.assign({}, this.defaultContactItem);
    this.getCompany();
  },
};
</script>

<style scoped>
.bottom-gradient {
  background-image: linear-gradient(
    to bottom,
    rgba(163, 153, 248, 0.295) 0%,
    rgba(101, 99, 248, 0.699) 100%
  );
}
.blend-opacity {
  opacity: 0.1;
  transition: opacity 0.4s ease-in-out;
  background-color: black;
  background-blend-mode: normal;
}
.boxdivider {
  background-color: transparent;
  width: 100px;
  height: 20px;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom-width: 3px;
  border-bottom-color: blueviolet;
  border-bottom-style: solid;
  display: block;
}
</style>
