import { render, staticRenderFns } from "./ContactsComponent.vue?vue&type=template&id=41681b1b&scoped=true"
import script from "./ContactsComponent.vue?vue&type=script&lang=js"
export * from "./ContactsComponent.vue?vue&type=script&lang=js"
import style0 from "./ContactsComponent.vue?vue&type=style&index=0&id=41681b1b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41681b1b",
  null
  
)

export default component.exports